import React, {useEffect} from "react";

import Layout from "../layouts/Layout";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import JoinTheProgram from "../components/JoinTheProgram";
import SocialShare from "../components/SocialShare";
import accordion from "../helper/accordion";

/**
 * Page template used for all pages that have no template defined.
 * @param pageContext
 */
const Page = ({ pageContext, data, location }) => {
  const page = data.page;
  const stringTranslations = pageContext.stringTranslations;

  useEffect(() => {
    accordion.init();
  });

  return (
    <Layout
      context={pageContext}
      className="page-page"
      location={location}
      progressBarTarget=".page-page__content"
    >
      <Row className="page-page__content">
        <Col lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} style={{ zIndex: 0}}>
          <article>
            <h1 dangerouslySetInnerHTML={{__html: page.post_title}} />
            <div className="content" dangerouslySetInnerHTML={{ __html: page.post_content }} />
            <JoinTheProgram
              link={pageContext.signUpLink}
              title={stringTranslations["Join the associates programme"]}
              benefit={stringTranslations["Earn up to 12% in commission income with a trusted e-commerce-leader"]}
              btnText={stringTranslations["Sign up"]}
              variant="big"
            />
          </article>
        </Col>
      </Row>

      <Row>
        <Col lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
          <SocialShare
            likeCount={3}
            postId={page.wordpress_id}
            path={page.path}
          />
        </Col>
      </Row>
    </Layout> 
  );
};

export const query = graphql`
  query PageQuery($id: Int!, $languageCode: String!) {
    page: wordpressPage(
      language: { language_code: { eq: $languageCode } }
      wordpress_id: { eq: $id }
    ) {
      wordpress_id
      path
      post_content
      post_title
      post_date(locale: $languageCode, formatString: "MMMM D, YYYY")
    }
  }
`;

export default Page;
