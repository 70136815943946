/**
 * Creates accordion functionality to yoast structured data faq blocks
 * @type {{init: accordion.init, select: accordion.select}}
 */
const accordion = {
  init: function() {
    var sections = document.querySelectorAll(".schema-faq-section");
    if (sections.length > 0) {
      for (var head of sections) {
        head.addEventListener("click", accordion.select);
      }
    }
  },

  select: function(element) {
    var question = element.target.closest(".schema-faq-question");
    var section = element.target.closest(".schema-faq-section");

    if (question && section) {
      question.getAttribute("aria-expanded")
        ? question.removeAttribute("aria-expanded")
        : question.setAttribute("aria-expanded", "true");
      section.classList.toggle("open");
    }
  }
};

export default accordion;
